import './App.css';
import Header from "./components/Header";
import PaySection from "./pages/PaySection";
import AboutUs from "./pages/AboutUs";
import HowItWorks from "./pages/HowItWorks";
import NavBar from "./components/NavBar";
import {useState} from "react";
import Tokenomics from "./pages/Tokenomics";
import BlockVector from "./components/BlockVector";
import BottomSection from "./pages/BottomSection";
import OurTeam from "./pages/OurTeam";

function App() {
  const [open, setOpen] = useState(false)

  const handleOpenMenu = () => {
    setOpen(true)
  }

  const handleCloseMenu = () => {
    setOpen(!open)
  }

  return (
    <div className="App">
      <Header handleOpenMenu={handleOpenMenu}/>
      { open && <NavBar handleCloseMenu={handleCloseMenu}/> }
      <PaySection/>
      <AboutUs/>
      <HowItWorks/>
      <Tokenomics/>
      <BlockVector/>
      <OurTeam/>
      <BottomSection/>
    </div>
  );
}

export default App;
