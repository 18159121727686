export const advantages = [
  {
    id: 0,
    name: 'Sustainable Fixed APY – 54,768 %',
    position: 'left'
  },
  {
    id: 1,
    name: 'Low risk with the Echtano Reserve',
    position: 'right'
  },
  {
    id: 2,
    name: 'Interest Paid Every 30 Minutes: 48 Times Daily!',
    position: 'left'
  },
  {
    id: 3,
    name: 'Automatic Staking and Compounding in Your Wallet!',
    position: 'right'
  },
]