import firstCrystal from "../../images/firstCrystal.svg"
import secondCrystal from "../../images/secondCrystal.svg"
import aze from "../../images/aze.png"
import jb from "../../images/jb.png"
import chainshark from "../../images/chainshark.png"

export const ourTeamData = [
  {
    id: 0,
    img: jb,
    userName: 'JB',
    description: 'JB has been in the crypto scene since 2015 thus creating perspicacity in what works and what doesn’t. Being already well connected in DeFi and marketing he has committed himself in becoming the upper echelon within the space over the coming years. Warrior by day. Jeet by night.',
    separator: firstCrystal
  },
  {
    id: 1,
    img: aze,
    userName: 'Aze',
    description: 'Expertise in Marketing. Sales B2B experience. Entrepeneur in blood and veins & stock market enthousiast.',
    separator: secondCrystal
  },
  {
    id: 2,
    img: chainshark,
    userName: 'Chainshark',
    description: 'Operates in IT Consultancy. Moderator/admin on Reddit for years, crossed over to crypto-themed Telegram groups last year. Well-connected in the DeFi space. Linguistic and creative mind.'
  },
]