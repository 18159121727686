import React, {useRef} from 'react'
import "./index.css"
import Container from "../Container"
import {Navigation, Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
import {partners} from "./variables"
import PartnersSliderArrow from "../PartnersSlideArrow"

const Index = (props) => {
    const swiperRef = useRef(null)

    return (
        <section className="partners">
            <Container>
                <h1>Our Partners</h1>
                <Swiper
                    ref={swiperRef}
                    className="trendsContainer"
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    navigation
                    modules={[Autoplay]}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                >
                    {
                        partners.map((item, idx) => {
                            return (
                                <SwiperSlide key={idx} className="imageContainer">
                                    <img src={item} alt=""/>
                                </SwiperSlide>
                            )
                        })
                    }
                    {/*<div className="partnersButtonContainer">*/}
                    <div className="swiper-button-next" onClick={() => swiperRef.current.swiper.slidePrev()}>
                        <PartnersSliderArrow/>
                    </div>
                    <div className="swiper-button-prev" onClick={() => swiperRef.current.swiper.slideNext()}>
                        <PartnersSliderArrow/>
                    </div>
                    {/*</div>*/}
                </Swiper>
            </Container>
        </section>
    )
}

export default Index