import React from 'react'
import './index.css'
import Container from "../../components/Container"
import Crystal from "../../images/crystal.svg"
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { slides } from "./variables"
import SliderArrow from "../../components/SliderArrow"

const HowItWorks = (props) => {
  const swiperRef = React.useRef(null)

  return (
    <section className="howItWorksSection" id="how-it-works">
        <Container>
          <div className="imageContainer">
            <img src={Crystal} alt=""/>
          </div>
          <h1>Features</h1>
          <Swiper
            ref={swiperRef}
            className="slidesContainer"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={30}
            slidesPerView={"auto"}
            centeredSlides={true}
            loop={true}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
          >
            {
              slides.map(item => {
                return (
                  <SwiperSlide key={item.id} className="cardContainer">
                    <div className="cardImageContainer">
                      {item.icon}
                    </div>
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          <div className="sliderButtonContainer">
            <div className="swiper-button-next" onClick={() => swiperRef.current.swiper.slideNext()}>
              <SliderArrow/>
            </div>
            <div className="swiper-button-prev" onClick={() => swiperRef.current.swiper.slidePrev()}>
              <SliderArrow/>
            </div>
          </div>
        </Container>
    </section>
  )
}

export default HowItWorks