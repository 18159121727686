import React from 'react'
import LineBg from "../../images/lineBg.svg"
import "./index.css"
import FirstAbsoluteIMage from '../../images/tokenomicsAbsoluteImage.svg'
import AbsoluteIMage from '../../images/faqAbsoluteImage.svg'

const BlockVector = (props) => {
  return (
    <div className="blockVector">
      <div className="firstImageContainer">
        <img src={FirstAbsoluteIMage} alt=""/>
      </div>
      <img className="vector" src={LineBg} alt="" style={{width: '100%', objectFit: 'cover', fill: 'red'}} />
      <div className="secondImageContainer">
        <img src={AbsoluteIMage} alt=""/>
      </div>
    </div>
  )
}

export default BlockVector