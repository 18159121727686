import React from 'react'
import "./index.css"
import Partners from "../../components/Partners"
import BottomNav from "../../components/BottomNav"
import Footer from "../../components/Footer"
import { FadeInWhenVisible } from '../../components/FadeInWhenVisible'

const BottomSection = () => {
  return (
    <section className="bottomSection">
      <div className="gradient">
        <FadeInWhenVisible animation="easyFadeIn" inView delay={0.2}>
          <Partners/>
        </FadeInWhenVisible>
        <BottomNav/>
        <Footer/>
      </div>
    </section>
  )
}

export default BottomSection