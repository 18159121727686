import React from 'react';
import Modal from "../Modal";
import "./index.css"
import {headerNavigation, headerSocLinks} from "../Header/variables";
import {Link} from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from '@fortawesome/free-solid-svg-icons'

const NavBar = (props) => {
    return (
        <Modal handleCloseMenu={props.handleCloseMenu}>
            <nav className='navBar'>
                <div className="closeIcon" onClick={props.handleCloseMenu}>
                    <FontAwesomeIcon icon={faXmark}/>
                </div>
                <div className="navMenuContainer">
                    <nav className='navMenu'>
                        {
                            headerNavigation.map(item => {
                                return (
                                    <>
                                        {
                                            item.id >= 4 ? <a href={item.link}>{item.name}</a> :
                                                <Link spy={true}
                                                      smooth={true}
                                                      offset={-70}
                                                      duration={500}
                                                      key={item.id}
                                                      onClick={props.handleCloseMenu}
                                                      to={item.link}>{item.name}</Link>
                                        }
                                    </>
                                )
                            })
                        }
                    </nav>
                </div>
                <div className="socialLinksContainer">
                    <nav className='socialLinks'>
                        {
                            headerSocLinks.map(item => {
                                return (
                                    <a className='soc' href={item.link} target="_blank">
                                        <img src={item.image} alt=""/>
                                    </a>
                                )
                            })
                        }
                    </nav>
                </div>
            </nav>
        </Modal>
    );
};

export default NavBar;