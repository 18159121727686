import React, {useEffect} from 'react'
import Container from "../../components/Container"
import './index.css'
import { advantages } from "./variables"
import { FadeInWhenVisible } from '../../components/FadeInWhenVisible'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import crystal from "../../animations/crystals.json"
import lottie from "lottie-web";

const PaySection = (props) => {

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#crystal"),
      animationData: crystal
    });
  }, []);

  return (
    <section className="paySection" id="pay-section">
      <Container>
        <div className="payDescriptionContainer">
          <FadeInWhenVisible animation="easyFadeIn" inView delay={0.2}>
            <h1>Engineered to last. Designed to reward</h1>
            <p>We at Echtano have measures in place to ensure sustainability and credibility for the benefit of our investors. Making this an ideal and safe long-term investment.
              <br/>
              <br/>
              Simply hold $ECHO and watch the tokens grow in your wallet, thanks to our unique Hold & Burn Algorithm. </p>
            <div className="buttonContainer">
              <div className="button bye">
                Buy Now
              </div>
              <div className="button">
                Open App
              </div>
            </div>
          </FadeInWhenVisible>
        </div>
        <FadeInWhenVisible animation="easyFadeIn" inView delay={0.4} className="imageContainer">
          <div id="crystal"/>
          <div className="advantagesContainer">
            {
              advantages.map(adv => {
                console.log(Number(`0.${adv.id}`))
                return (
                  <FadeInWhenVisible animation="easyFadeIn" inView delay={Number(`0.${adv.id + 6}`)}>
                    <div key={adv.id} className={`advantage ${adv.position === 'left' ? 'left' : 'right'}`}>
                      <FontAwesomeIcon icon={faCircleCheck}/>
                      <span>{adv.name}</span>
                    </div>
                  </FadeInWhenVisible>
                )
              })
            }
            {/*</div>*/}
          </div>
        </FadeInWhenVisible>
      </Container>
    </section>
  )
}

export default PaySection
