import React from 'react';

const PartnersSlideArrow = (props) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx="28" cy="27.9995" r="27.5" stroke="#211E31"/>
      <path opacity="0.5" d="M17 27.9995L33.5 15.8752V40.1239L17 27.9995Z" fill="#211E31"/>
    </svg>

  );
};

export default PartnersSlideArrow;