import React from 'react';
import Container from "../Container";
import {bottomMenu} from "./variables";
import "./index.css";
import {Link} from "react-scroll";

const BottomNav = (props) => {
  return (
    <nav className="bottomNav">
      <Container>
        {
          bottomMenu.map(item => {
            return (
              <>
                  {
                          item.id >= 5 ? <a href={item.link} target="_blank">{item.name}</a> :
                              <Link spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    key={item.id}
                                    onClick={props.handleCloseMenu}
                                    to={item.link}>{item.name}</Link>
                  }
                <span>{item.id < 6 && "/"}</span>
              </>
            )
          })
        }
      </Container>
    </nav>
  );
};

export default BottomNav;