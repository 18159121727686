export const bottomMenu = [
  {
    id: 0,
    name: 'Home',
    link: '',
  },
  {
    id: 1,
    name: 'About Us',
    link: 'about-us',
  },
  {
    id: 2,
    name: 'Features',
    link: 'how-it-works',
  },
  {
    id: 3,
    name: 'Tokenomics',
    link: 'tokenomics',
  },
  {
    id: 4,
    name: 'Out team',
    link: 'faq',
  },
  {
    id: 5,
    name: 'Bridge',
    link: 'https://app.ech.network/',
  },
  {
    id: 6,
    name: 'Faucet',
    link: 'https://app.ech.network/faucet',
  },
]