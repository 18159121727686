import React from 'react';
import './index.css'
import Container from "../Container";
// import Logo from '../../images/logo.svg'
import {headerNavigation, headerSocLinks} from "./variables";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-scroll";
import Logo from "../Logo";

const Header = (props) => {
    return (
        <header className='header'>
            <Container>
                <div className="navMenuContainer">
                    <Logo/>
                    <nav className='navMenu'>
                        {
                            headerNavigation.map(item => {
                                return (
                                    <>
                                        {
                                            item.id >= 4 ? <a href={item.link} target="_blank">{item.name}</a> :
                                                <Link spy={true}
                                                      smooth={true}
                                                      offset={-70}
                                                      duration={500}
                                                      key={item.id}
                                                      onClick={props.handleCloseMenu}
                                                      to={item.link}>{item.name}</Link>
                                        }
                                    </>)
                            })
                        }
                    </nav>
                    <div className="socialLinksContainer">
                        <nav className='socialLinks'>
                            {
                                headerSocLinks.map(item => {
                                    return (
                                        <a className='soc' href={item.link} target="_blank">
                                            <img src={item.image} alt=""/>
                                        </a>
                                    )
                                })
                            }
                        </nav>
                    </div>
                </div>
                <div className="menuIcon" onClick={props.handleOpenMenu}>
                    <FontAwesomeIcon icon={faBars}/>
                </div>
            </Container>
        </header>
    );
};

export default Header;