import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { AnimatePresence, motion, TargetAndTransition, Transition, useAnimation, VariantLabels } from 'framer-motion'
import { Variant } from 'framer-motion'

export const FadeAnimations = {
  'default': {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  },
  'easyFadeIn': {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0.75 },
  },
  'easyFadeInStill': {
    visible: { opacity: 1, scale: 1, x: 0 },
    hidden: { opacity: 0, scale: 1, x: 0 },
  },
  'easyFadeOut': {
    visible: { opacity: 1, scale: -1 },
    hidden: { opacity: 0, scale: -0.75 },
  },
  'childByChild': {
    visible: { opacity: 1, scale: 1, x: 10 },
    hidden: { opacity: 0, scale: 1, x: 0 },
  },
  'easySlidingDownFadeOut': {
    visible: { opacity: 1, scale: 1, y: -20 },
    hidden: { opacity: 0, scale: 1, y: 20 },
  },
  'bounceScale': {
    visible: {
      scale: [0, 0.4, 0.8, 1.2, 1],
      transition: {
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        duration: 1,
      },
    },
    hidden: { scale: 0 },
  },
  'bounceScaleLess': {
    visible: {
      scale: [0, 0.4, 0.8, 1.05, 1],
      transition: {
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        duration: 1,
      },
    },
    hidden: { scale: 0 },
  },
  'easySlidingLeft': {
    visible: {
      x: 0,
      transition: {
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        duration: 1,
      },
    },
    hidden: {
      x: -200,
    },
  },
  'easySlideLeft': {
    visible: {
      opacity: 1,
      x: 0,
    },
    hidden: {
      opacity: 0,
      x: -20,
    },
  },
  'easySlideRight': {
    initial: {
      x: -1000,
    },
    visible: {
      opacity: 1,
      x: -3,
      left: 0,
    },
    hidden: {
      opacity: 0,
      x: -20,
    },
  },
}

export const FadeInWhenVisible = (props) => {
  const {
    children,
    everyVisible,
    exitAnimation,
    reRenderKey,
    isImg,
    srcImg,
    delay,
    className,
  } = props

  let animation = props.animation || undefined
  const transition = props.transition || { duration: 0.3, delay: delay }

  if (typeof animation === 'string') {
    animation = FadeAnimations[animation]
  }

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }

    if (!inView && everyVisible) {
      controls.start('hidden')
    }
  })

  return (
    <>
      {
        isImg
          ?
          <motion.img
            ref={ref}
            key={reRenderKey}
            src={srcImg}
            transition={transition}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 20 }}
            exit={{ opacity: 0, y: -20 }}
            alt="img"
          />
          :
          <AnimatePresence>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              exit={exitAnimation}
              transition={transition}
              variants={animation}
              key={reRenderKey}
              className={className && className}
            >
              {children}
            </motion.div>
          </AnimatePresence>
      }
    </>
  )
}