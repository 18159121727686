import React from 'react';
import "./index.css";
import Container from "../Container";
import {headerSocLinks} from "../Header/variables";
import Logo from "../Logo";

const Index = (props) => {
  return (
    <footer className="footer">
      <Container>
        <Logo/>
        <div className="socialLinksContainer">
          <nav className='socialLinks'>
            {
              headerSocLinks.map(item => {
                return (
                    <a className='soc' href={item.link} target="_blank">
                      <img src={item.image} alt=""/>
                    </a>
                )
              })
            }
          </nav>
        </div>
        <p>© Echtano 2022. All Rights Reserved</p>
      </Container>
    </footer>
  );
};

export default Index;