// import firstSlideIcon from '../../images/firstSlideIcon.svg'
// import secondSlideIcon from '../../images/secondSlideIcon.svg'
// import thirdSlideIcon from '../../images/thirdSlideIcon.svg'

export const slides = [
    {
        id: 0,
        icon: <svg width="64" height="60" viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3"
                  id="first"
                  d="M1.63402 35.9675L11.5174 53.1533V53.1322C12.5944 54.993 14.1394 56.5382 15.9981 57.6135C17.8569 58.6888 19.9643 59.2568 22.1103 59.2606H41.8897C44.0358 59.2572 46.1434 58.6894 48.0022 57.6141C49.861 56.5387 51.4059 54.9933 52.4826 53.1322L62.366 35.9675C63.4365 34.1026 64 31.9886 64 29.8369C64 27.6853 63.4365 25.5713 62.366 23.7064L52.4826 6.5418C51.4048 4.67879 49.858 3.13221 47.9968 2.05672C46.1356 0.981223 44.0253 0.414533 41.8771 0.41333H22.1103C19.9643 0.41717 17.8569 0.985081 15.9981 2.06043C14.1394 3.13576 12.5944 4.68093 11.5174 6.5418L1.63402 23.7064C0.563455 25.5713 0 27.6853 0 29.8369C0 31.9886 0.563455 34.1026 1.63402 35.9675Z"
                  fill="#E2DCD0"/>
            <path opacity="0.3"
                  d="M-6.8993 35.969L2.98407 53.1547V53.1336C4.06112 54.9945 5.60607 56.5396 7.46478 57.615C9.32353 58.6903 11.431 59.2583 13.577 59.2621H33.3564C35.5024 59.2587 37.6101 58.6909 39.4689 57.6155C41.3277 56.5401 42.8726 54.9948 43.9493 53.1336L53.8326 35.969C54.9032 34.104 55.4667 31.9901 55.4667 29.8384C55.4667 27.6867 54.9032 25.5728 53.8326 23.7079L43.9493 6.54326C42.8715 4.68026 41.3246 3.13368 39.4634 2.05819C37.6022 0.982688 35.492 0.415998 33.3438 0.414795H13.577C11.431 0.418626 9.32353 0.986545 7.46478 2.06189C5.60607 3.13723 4.06112 4.6824 2.98407 6.54326L-6.8993 23.7079C-7.96987 25.5728 -8.53333 27.6867 -8.53333 29.8384C-8.53333 31.9901 -7.96987 34.104 -6.8993 35.969Z"
                  fill="white"/>
            <g opacity="0.5">
                <path
                    d="M41.7438 27.7416C41.7066 27.5803 41.6655 27.4206 41.6206 27.2624C41.5083 26.8669 41.3722 26.4815 41.2141 26.1076C41.1508 25.958 41.0841 25.8104 41.0139 25.6647C40.9788 25.5918 40.9428 25.5194 40.9059 25.4475C39.2495 22.2134 35.8832 20 32 20H32H31.9999C28.1168 20 24.7505 22.2134 23.0941 25.4474C23.0572 25.5193 23.0213 25.5917 22.9861 25.6646C22.8808 25.8832 22.7832 26.1062 22.6937 26.3333C22.5743 26.636 22.4692 26.946 22.3794 27.2623C22.3345 27.4206 22.2934 27.5803 22.2561 27.7415C22.0886 28.4673 22.0001 29.2233 22.0001 30C22.0001 30.7766 22.0886 31.5326 22.2561 32.2584C22.2934 32.4197 22.3345 32.5794 22.3794 32.7376C22.4692 33.0539 22.5743 33.364 22.6937 33.6667C22.7832 33.8938 22.8808 34.1168 22.9861 34.3354C23.0213 34.4083 23.0572 34.4806 23.0941 34.5526C24.7505 37.7866 28.1168 40 32 40H32H32.0001C35.8833 40 39.2495 37.7866 40.906 34.5526C40.9428 34.4807 40.9788 34.4083 41.0139 34.3354C41.0842 34.1897 41.1509 34.042 41.2141 33.8925C41.3723 33.5186 41.5083 33.1331 41.6207 32.7377C41.6656 32.5794 41.7067 32.4197 41.7439 32.2585C41.9114 31.5327 42 30.7767 42 30C42 29.2234 41.9113 28.4674 41.7438 27.7416ZM36.486 30L32 35.8915L27.5139 30L32 24.1084L36.486 30Z"
                    fill="white"/>
            </g>
        </svg>
        ,
        title: '$ECHO Token',
        description: `$ECHO is the native token which interest rebase rewards are paid. Every token holder automatically receives interest every 30 minutes just by holding $ECHO token in their wallet.`,
    },
    {
        id: 1,
        icon: <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_23_374" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="3" width="64"
                  height="60">
                <path
                    d="M1.63402 38.9675L11.5174 56.1533V56.1322C12.5944 57.993 14.1394 59.5382 15.9981 60.6135C17.8569 61.6888 19.9643 62.2568 22.1103 62.2606H41.8897C44.0358 62.2572 46.1434 61.6894 48.0022 60.6141C49.861 59.5387 51.4059 57.9933 52.4826 56.1322L62.366 38.9675C63.4365 37.1026 64 34.9886 64 32.8369C64 30.6853 63.4365 28.5713 62.366 26.7064L52.4826 9.5418C51.4048 7.67879 49.858 6.13221 47.9968 5.05672C46.1356 3.98122 44.0253 3.41453 41.8771 3.41333H22.1103C19.9643 3.41717 17.8569 3.98508 15.9981 5.06043C14.1394 6.13576 12.5944 7.68093 11.5174 9.5418L1.63402 26.7064C0.563455 28.5713 0 30.6853 0 32.8369C0 34.9886 0.563455 37.1026 1.63402 38.9675Z"
                    fill="#00DC82"/>
            </mask>
            <g mask="url(#mask0_23_374)">
                <path
                    id="first"
                    opacity="0.3"
                    d="M1.63402 38.9675L11.5174 56.1533V56.1322C12.5944 57.993 14.1394 59.5382 15.9981 60.6135C17.8569 61.6888 19.9643 62.2568 22.1103 62.2606H41.8897C44.0358 62.2572 46.1434 61.6894 48.0022 60.6141C49.861 59.5387 51.4059 57.9933 52.4826 56.1322L62.366 38.9675C63.4365 37.1026 64 34.9886 64 32.8369C64 30.6853 63.4365 28.5713 62.366 26.7064L52.4826 9.5418C51.4048 7.67879 49.858 6.13221 47.9968 5.05672C46.1356 3.98122 44.0253 3.41453 41.8771 3.41333H22.1103C19.9643 3.41717 17.8569 3.98508 15.9981 5.06043C14.1394 6.13576 12.5944 7.68094 11.5174 9.5418L1.63402 26.7064C0.563455 28.5713 0 30.6853 0 32.8369C0 34.9886 0.563455 37.1026 1.63402 38.9675Z"
                    fill="#E2DCD0"/>
                <path opacity="0.3"
                      d="M-6.8993 38.969L2.98407 56.1547V56.1336C4.06112 57.9945 5.60607 59.5396 7.46478 60.615C9.32353 61.6903 11.431 62.2583 13.577 62.2621H33.3564C35.5024 62.2587 37.6101 61.6909 39.4689 60.6155C41.3277 59.5401 42.8726 57.9948 43.9493 56.1336L53.8326 38.969C54.9032 37.104 55.4667 34.9901 55.4667 32.8384C55.4667 30.6867 54.9032 28.5728 53.8326 26.7079L43.9493 9.54326C42.8715 7.68026 41.3246 6.13368 39.4634 5.05819C37.6022 3.98269 35.492 3.416 33.3438 3.41479H13.577C11.431 3.41863 9.32353 3.98655 7.46478 5.06189C5.60607 6.13723 4.06112 7.6824 2.98407 9.54326L-6.8993 26.7079C-7.96987 28.5728 -8.53333 30.6867 -8.53333 32.8384C-8.53333 34.9901 -7.96987 37.104 -6.8993 38.969Z"
                      fill="white"/>
                <path
                    d="M30.75 25.75L31.375 24.5L32.625 23.875L31.375 23.25L30.75 22L30.125 23.25L28.875 23.875L30.125 24.5L30.75 25.75ZM25.125 28.25L26.1665 26.1668L28.25 25.125L26.1665 24.0832L25.125 22L24.0836 24.0832L22 25.125L24.0836 26.1668L25.125 28.25ZM38.875 33.25L37.8336 35.3332L35.75 36.375L37.8336 37.4168L38.875 39.5L39.9165 37.4168L42 36.375L39.9165 35.3332L38.875 33.25ZM41.6336 25.6809L38.3192 22.3664C38.0754 22.1219 37.7555 22 37.4356 22C37.1157 22 36.7957 22.1219 36.5516 22.3664L22.3665 36.5516C21.8782 37.0399 21.8782 37.8312 22.3665 38.3191L25.6809 41.6336C25.925 41.8777 26.245 41.9996 26.5645 41.9996C26.8844 41.9996 27.2043 41.8777 27.4485 41.6336L41.6336 27.4481C42.1219 26.9606 42.1219 26.1688 41.6336 25.6809ZM36.041 29.9476L34.0524 27.959L37.4352 24.5761L39.4239 26.5649L36.041 29.9476Z"
                    fill="white"/>
            </g>
        </svg>
        ,
        title: 'Auto-Compounding',
        description: `Echelon’s most optimized auto-staking and auto-compounding protocol with an APY of 54,768%. Interest rewards are compounded every 30 minutes for every ECH wallet holding any $ECHO tokens.`,
    },
    {
        id: 2,
        icon: <svg width="64" height="60" viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3"
                  id="first"
                  d="M1.63402 35.9675L11.5174 53.1533V53.1322C12.5944 54.993 14.1394 56.5382 15.9981 57.6135C17.8569 58.6888 19.9643 59.2568 22.1103 59.2606H41.8897C44.0358 59.2572 46.1434 58.6894 48.0022 57.6141C49.861 56.5387 51.4059 54.9933 52.4826 53.1322L62.366 35.9675C63.4365 34.1026 64 31.9886 64 29.8369C64 27.6853 63.4365 25.5713 62.366 23.7064L52.4826 6.5418C51.4048 4.67879 49.858 3.13221 47.9968 2.05672C46.1356 0.981223 44.0253 0.414533 41.8771 0.41333H22.1103C19.9643 0.41717 17.8569 0.985081 15.9981 2.06043C14.1394 3.13576 12.5944 4.68094 11.5174 6.5418L1.63402 23.7064C0.563455 25.5713 0 27.6853 0 29.8369C0 31.9886 0.563455 34.1026 1.63402 35.9675Z"
                  fill="#E2DCD0"/>
            <path opacity="0.3"
                  d="M-6.8993 35.969L2.98407 53.1547V53.1336C4.06112 54.9945 5.60607 56.5396 7.46478 57.615C9.32353 58.6903 11.431 59.2583 13.577 59.2621H33.3564C35.5024 59.2587 37.6101 58.6909 39.4689 57.6155C41.3277 56.5401 42.8726 54.9948 43.9493 53.1336L53.8326 35.969C54.9032 34.104 55.4667 31.9901 55.4667 29.8384C55.4667 27.6867 54.9032 25.5728 53.8326 23.7079L43.9493 6.54326C42.8715 4.68026 41.3246 3.13368 39.4634 2.05819C37.6022 0.982688 35.492 0.415998 33.3438 0.414795H13.577C11.431 0.418626 9.32353 0.986545 7.46478 2.06189C5.60607 3.13723 4.06112 4.6824 2.98407 6.54326L-6.8993 23.7079C-7.96987 25.5728 -8.53333 27.6867 -8.53333 29.8384C-8.53333 31.9901 -7.96987 34.104 -6.8993 35.969Z"
                  fill="white"/>
            <g opacity="0.5">
                <path
                    d="M41.716 32.654L40.2091 31.6187C40.0617 29.05 38.0865 26.8573 35.2856 25.8316C35.0446 27.5907 33.532 28.9502 31.7075 28.9502C29.8838 28.9502 28.3706 27.5907 28.1302 25.8316C27.9793 25.8872 27.8307 25.9459 27.6845 26.0076L25.1472 25.3686C24.9856 25.3277 24.8139 25.3625 24.6815 25.4625C24.5482 25.5629 24.4677 25.7185 24.4626 25.8848L24.3807 28.5103C24.1168 28.8628 23.8907 29.2358 23.7114 29.6259H22.5756C22.258 29.6259 22 30.6561 22 31.928C22 33.1998 22.258 34.2296 22.5757 34.2296H23.7114C23.9569 34.7627 24.2871 35.264 24.6866 35.724V38.6825C24.6866 39.0749 25.0043 39.394 25.3962 39.394H28.121C28.5129 39.394 28.8313 39.0749 28.8313 38.6825V38.2511C29.7304 38.5054 30.6992 38.6444 31.7082 38.6444C32.7178 38.6444 33.6854 38.5054 34.5845 38.2511V38.842C34.5845 39.1463 34.8302 39.3939 35.1349 39.3939H38.0189C38.4115 39.3939 38.7292 39.0749 38.7292 38.6824V35.7239C39.3964 34.9566 39.8668 34.075 40.0831 33.1235L40.9726 33.7348C41.0863 33.8131 41.2163 33.8501 41.3436 33.8501C41.5522 33.8501 41.7574 33.7508 41.8849 33.5663C42.0895 33.2677 42.0142 32.8593 41.716 32.654Z"
                    fill="white"/>
                <path
                    d="M31.7076 28.0765C33.2195 28.0765 34.4449 26.8511 34.4449 25.3395C34.4449 23.8279 33.2196 22.6025 31.7076 22.6025C30.1957 22.6025 28.971 23.8279 28.971 25.3395C28.971 26.851 30.1957 28.0765 31.7076 28.0765Z"
                    fill="white"/>
                <path
                    d="M36.4571 23.1093L38.1542 23.5543L38.6 25.2514C38.6148 25.3108 38.6681 25.3518 38.7293 25.3518C38.791 25.3518 38.8442 25.3105 38.8598 25.2514L39.3042 23.5543L41.0027 23.1093C41.061 23.0937 41.1019 23.0404 41.1019 22.9793C41.1019 22.9179 41.061 22.8643 41.0027 22.8487L39.3042 22.404L38.8598 20.7066C38.8442 20.6478 38.791 20.6062 38.7293 20.6062C38.6681 20.6062 38.6149 20.6477 38.6 20.7066L38.1542 22.404L36.4571 22.8487C36.398 22.8643 36.3565 22.9179 36.3565 22.9793C36.3564 23.0404 36.398 23.0937 36.4571 23.1093Z"
                    fill="white"/>
            </g>
        </svg>,
        title: 'SHIELD',
        description: `Every 48 hours our Smart Hedge Injected Elastic Liquidity Driver (SHIELD) will inject automatic liquidity into the market. On each buy or sell order there is a 4% tax fee that automatically gets stored into an Auto-LP wallet and built into our protocol’s smart contract is the mechanism which smartly takes the 50% of the amount of $ECHO stored in the wallet, and will automatically buy ECH at the current market price.`,
    },
    {
        id: 3,
        icon: <svg width="64" height="60" viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3"
                  id="first"
                  d="M1.63402 35.9675L11.5174 53.1533V53.1322C12.5944 54.993 14.1394 56.5382 15.9981 57.6135C17.8569 58.6888 19.9643 59.2568 22.1103 59.2606H41.8897C44.0358 59.2572 46.1434 58.6894 48.0022 57.6141C49.861 56.5387 51.4059 54.9933 52.4826 53.1322L62.366 35.9675C63.4365 34.1026 64 31.9886 64 29.8369C64 27.6853 63.4365 25.5713 62.366 23.7064L52.4826 6.5418C51.4048 4.67879 49.858 3.13221 47.9968 2.05672C46.1356 0.981223 44.0253 0.414533 41.8771 0.41333H22.1103C19.9643 0.41717 17.8569 0.985081 15.9981 2.06043C14.1394 3.13576 12.5944 4.68093 11.5174 6.5418L1.63402 23.7064C0.563455 25.5713 0 27.6853 0 29.8369C0 31.9886 0.563455 34.1026 1.63402 35.9675Z"
                  fill="#E2DCD0"/>
            <path opacity="0.3"
                  d="M-6.8993 35.969L2.98407 53.1547V53.1336C4.06112 54.9945 5.60607 56.5396 7.46478 57.615C9.32353 58.6903 11.431 59.2583 13.577 59.2621H33.3564C35.5024 59.2587 37.6101 58.6909 39.4689 57.6155C41.3277 56.5401 42.8726 54.9948 43.9493 53.1336L53.8326 35.969C54.9032 34.104 55.4667 31.9901 55.4667 29.8384C55.4667 27.6867 54.9032 25.5728 53.8326 23.7079L43.9493 6.54326C42.8715 4.68026 41.3246 3.13368 39.4634 2.05819C37.6022 0.982688 35.492 0.415998 33.3438 0.414795H13.577C11.431 0.418626 9.32353 0.986545 7.46478 2.06189C5.60607 3.13723 4.06112 4.6824 2.98407 6.54326L-6.8993 23.7079C-7.96987 25.5728 -8.53333 27.6867 -8.53333 29.8384C-8.53333 31.9901 -7.96987 34.104 -6.8993 35.969Z"
                  fill="white"/>
            <g opacity="0.5">
                <path
                    d="M41.7438 27.7416C41.7066 27.5803 41.6655 27.4206 41.6206 27.2624C41.5083 26.8669 41.3722 26.4815 41.2141 26.1076C41.1508 25.958 41.0841 25.8104 41.0139 25.6647C40.9788 25.5918 40.9428 25.5194 40.9059 25.4475C39.2495 22.2134 35.8832 20 32 20H32H31.9999C28.1168 20 24.7505 22.2134 23.0941 25.4474C23.0572 25.5193 23.0213 25.5917 22.9861 25.6646C22.8808 25.8832 22.7832 26.1062 22.6937 26.3333C22.5743 26.636 22.4692 26.946 22.3794 27.2623C22.3345 27.4206 22.2934 27.5803 22.2561 27.7415C22.0886 28.4673 22.0001 29.2233 22.0001 30C22.0001 30.7766 22.0886 31.5326 22.2561 32.2584C22.2934 32.4197 22.3345 32.5794 22.3794 32.7376C22.4692 33.0539 22.5743 33.364 22.6937 33.6667C22.7832 33.8938 22.8808 34.1168 22.9861 34.3354C23.0213 34.4083 23.0572 34.4806 23.0941 34.5526C24.7505 37.7866 28.1168 40 32 40H32H32.0001C35.8833 40 39.2495 37.7866 40.906 34.5526C40.9428 34.4807 40.9788 34.4083 41.0139 34.3354C41.0842 34.1897 41.1509 34.042 41.2141 33.8925C41.3723 33.5186 41.5083 33.1331 41.6207 32.7377C41.6656 32.5794 41.7067 32.4197 41.7439 32.2585C41.9114 31.5327 42 30.7767 42 30C42 29.2234 41.9113 28.4674 41.7438 27.7416ZM36.486 30L32 35.8915L27.5139 30L32 24.1084L36.486 30Z"
                    fill="white"/>
            </g>
        </svg>
        ,
        title: 'Dynamic Taxes',
        description: `Dynamic taxes are an innovative concept, which aims to minimize price manipulation by taxing sales/wallet transfers additionally based on how big of a share the holder has in correlation to the LP of ECHO. This means that somebody with a lot of tokens in the ecosystem cannot dump the market without leaving a share of it in the hands of the community, making future attempts harder and harder.`,
    },
    {
        id: 4,
        icon: <svg width="64" height="60" viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3"
                  id="first"
                  d="M1.63402 35.9675L11.5174 53.1533V53.1322C12.5944 54.993 14.1394 56.5382 15.9981 57.6135C17.8569 58.6888 19.9643 59.2568 22.1103 59.2606H41.8897C44.0358 59.2572 46.1434 58.6894 48.0022 57.6141C49.861 56.5387 51.4059 54.9933 52.4826 53.1322L62.366 35.9675C63.4365 34.1026 64 31.9886 64 29.8369C64 27.6853 63.4365 25.5713 62.366 23.7064L52.4826 6.5418C51.4048 4.67879 49.858 3.13221 47.9968 2.05672C46.1356 0.981223 44.0253 0.414533 41.8771 0.41333H22.1103C19.9643 0.41717 17.8569 0.985081 15.9981 2.06043C14.1394 3.13576 12.5944 4.68094 11.5174 6.5418L1.63402 23.7064C0.563455 25.5713 0 27.6853 0 29.8369C0 31.9886 0.563455 34.1026 1.63402 35.9675Z"
                  fill="#E2DCD0"/>
            <path opacity="0.3"
                  d="M-6.8993 35.969L2.98407 53.1547V53.1336C4.06112 54.9945 5.60607 56.5396 7.46478 57.615C9.32353 58.6903 11.431 59.2583 13.577 59.2621H33.3564C35.5024 59.2587 37.6101 58.6909 39.4689 57.6155C41.3277 56.5401 42.8726 54.9948 43.9493 53.1336L53.8326 35.969C54.9032 34.104 55.4667 31.9901 55.4667 29.8384C55.4667 27.6867 54.9032 25.5728 53.8326 23.7079L43.9493 6.54326C42.8715 4.68026 41.3246 3.13368 39.4634 2.05819C37.6022 0.982688 35.492 0.415998 33.3438 0.414795H13.577C11.431 0.418626 9.32353 0.986545 7.46478 2.06189C5.60607 3.13723 4.06112 4.6824 2.98407 6.54326L-6.8993 23.7079C-7.96987 25.5728 -8.53333 27.6867 -8.53333 29.8384C-8.53333 31.9901 -7.96987 34.104 -6.8993 35.969Z"
                  fill="white"/>
            <g opacity="0.5">
                <path
                    d="M41.716 32.654L40.2091 31.6187C40.0617 29.05 38.0865 26.8573 35.2856 25.8316C35.0446 27.5907 33.532 28.9502 31.7075 28.9502C29.8838 28.9502 28.3706 27.5907 28.1302 25.8316C27.9793 25.8872 27.8307 25.9459 27.6845 26.0076L25.1472 25.3686C24.9856 25.3277 24.8139 25.3625 24.6815 25.4625C24.5482 25.5629 24.4677 25.7185 24.4626 25.8848L24.3807 28.5103C24.1168 28.8628 23.8907 29.2358 23.7114 29.6259H22.5756C22.258 29.6259 22 30.6561 22 31.928C22 33.1998 22.258 34.2296 22.5757 34.2296H23.7114C23.9569 34.7627 24.2871 35.264 24.6866 35.724V38.6825C24.6866 39.0749 25.0043 39.394 25.3962 39.394H28.121C28.5129 39.394 28.8313 39.0749 28.8313 38.6825V38.2511C29.7304 38.5054 30.6992 38.6444 31.7082 38.6444C32.7178 38.6444 33.6854 38.5054 34.5845 38.2511V38.842C34.5845 39.1463 34.8302 39.3939 35.1349 39.3939H38.0189C38.4115 39.3939 38.7292 39.0749 38.7292 38.6824V35.7239C39.3964 34.9566 39.8668 34.075 40.0831 33.1235L40.9726 33.7348C41.0863 33.8131 41.2163 33.8501 41.3436 33.8501C41.5522 33.8501 41.7574 33.7508 41.8849 33.5663C42.0895 33.2677 42.0142 32.8593 41.716 32.654Z"
                    fill="white"/>
                <path
                    d="M31.7076 28.0765C33.2195 28.0765 34.4449 26.8511 34.4449 25.3395C34.4449 23.8279 33.2196 22.6025 31.7076 22.6025C30.1957 22.6025 28.971 23.8279 28.971 25.3395C28.971 26.851 30.1957 28.0765 31.7076 28.0765Z"
                    fill="white"/>
                <path
                    d="M36.4571 23.1093L38.1542 23.5543L38.6 25.2514C38.6148 25.3108 38.6681 25.3518 38.7293 25.3518C38.791 25.3518 38.8442 25.3105 38.8598 25.2514L39.3042 23.5543L41.0027 23.1093C41.061 23.0937 41.1019 23.0404 41.1019 22.9793C41.1019 22.9179 41.061 22.8643 41.0027 22.8487L39.3042 22.404L38.8598 20.7066C38.8442 20.6478 38.791 20.6062 38.7293 20.6062C38.6681 20.6062 38.6149 20.6477 38.6 20.7066L38.1542 22.404L36.4571 22.8487C36.398 22.8643 36.3565 22.9179 36.3565 22.9793C36.3564 23.0404 36.398 23.0937 36.4571 23.1093Z"
                    fill="white"/>
            </g>
        </svg>,
        title: 'Echtano Reserve (ER)',
        description: `ER is the acronym for the Echtano Reserve which is a separate wallet in Echtano’s  system. The ER uses an algorithm that backs the Rebase Rewards and is supported by a portion of the buy and sell trading fees that accrue in the ER wallet.`,
    },
]