import React, {useState} from 'react';
import Container from "../../components/Container";
import "./index.css";
import {tokenomics, chunk} from "./variables";
import {FadeInWhenVisible} from '../../components/FadeInWhenVisible'
import Crystal from "../../images/crystal2.svg"


const Tokenomics = (props) => {
    const [columns] = useState(chunk(tokenomics, 2))

    return (
        <section className="tokenomicsSection" id="tokenomics">
            <Container>
                <FadeInWhenVisible animation="easyFadeIn" inView delay={0.2} className="descriptionContainer">
                    <div className="imageContainer">
                        <img src={Crystal} alt=""/>
                    </div>
                    <h1>Tokenomics</h1>
                    <p>$ECHO is a token operating on the Echelon chain with an elastic supply that provides holders with
                        rewards through consistent and sustained positive rebases.
                    </p>
                    <div className="buttonContainer">
                        <div className="button">
                            Buy Now
                        </div>
                    </div>
                </FadeInWhenVisible>
                <div className="tokenomicsItemsContainer">
                    {
                        columns.map((col, idx) => {
                            return (
                                <div className={`tokenomicsColumn ${idx === 0 ? 'first' : ''}`}>
                                    {
                                        col.map(item => {
                                            return (
                                                <FadeInWhenVisible animation="easyFadeIn" inView
                                                                   delay={Number(`0.${2 + item.id}`)}
                                                                   className={`tokenomicsItem ${item.active === true ? 'active' : ''}`}>
                                                    <div className="imageContainer">
                                                        {item.icon}
                                                    </div>
                                                    <h3>{item.title}</h3>
                                                    <p>{item.description}</p>
                                                </FadeInWhenVisible>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </Container>
        </section>
    );
};

export default Tokenomics;