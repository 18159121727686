import React from 'react';
import "./index.css";
import Container from "../../components/Container";
import {ourTeamData} from "./variables";
import {FadeInWhenVisible} from "../../components/FadeInWhenVisible";

const OurTeam = (props) => {
    return (
        <section className="teamSection" id="faq">
            <Container>
                <div className="titleContainer">
                    <FadeInWhenVisible animation="easyFadeIn" delay={0.2} inView>
                        <h1>Our Team</h1>
                    </FadeInWhenVisible>
                </div>
                <div className="usersListContainer">
                    {
                        ourTeamData.map(item => {
                            return (
                                <FadeInWhenVisible animation="easyFadeIn" delay={0.5} className="userListItem" key={item.id} inView>
                                    <div className="userInfo">
                                        <div className="imageContainer">
                                            <img src={item.img} alt=""/>
                                        </div>
                                        <div className="textContainer">
                                            <h3>{item.userName}</h3>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                    {item.separator && <img className="crystal" src={item.separator} alt=""/>}
                                </FadeInWhenVisible>
                            )
                        })
                    }
                </div>
            </Container>
        </section>
    );
};

export default OurTeam;