import React from 'react';
import "./index.css";
import logoImage from "../../images/logoImage.svg"

export default function Logo () {
  return (
    <div className="logoContainer">
          <div className="imageContainer">
              <img src={logoImage} alt=""/>
          </div>
        <h3>Echtano</h3>
    </div>
  );
};
