import React from 'react';

const SliderArrow = (props) => {
  return (
    <div>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.3" cx="28" cy="28" r="27.5" stroke="#D9D4E0"/>
        <path opacity="0.3" d="M17 28L33.5 15.8756V40.1244L17 28Z" fill="white"/>
      </svg>
    </div>

  );
};

export default SliderArrow;