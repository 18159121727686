import Twitter from '../../images/twitter.svg'
import Discord from '../../images/discord.svg'
import Telegram from '../../images/telegram.svg'

export const headerNavigation = [
  {
    id: 0,
    name: 'About Us',
    link: 'about-us',
  },
  {
    id: 1,
    name: 'Features',
    link: 'how-it-works',
  },
  {
    id: 2,
    name: 'Tokenomics',
    link: 'tokenomics',
  },
  {
    id: 3,
    name: 'Our team',
    link: 'faq',
  },
  {
    id: 4,
    name: 'Bridge',
    link: 'https://app.ech.network/',
  },
  {
    id: 5,
    name: 'Faucet',
    link: 'https://app.ech.network/faucet',
  },
]

export const headerSocLinks = [
  {
    id: 0,
    link: 'https://discord.gg/7WtWaA2S',
    image: Discord,
  },
  {
    id: 1,
    link: 'http://twitter.com/echtano',
    image: Twitter,
  },
  {
    id: 2,
    link: 'https://t.me/EchtanoPortal',
    image: Telegram,
  }
]