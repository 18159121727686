import React, {useEffect} from 'react'
import './index.css'
import './responsive.css'
import Container from "../../components/Container"
import {FadeInWhenVisible} from '../../components/FadeInWhenVisible'
import coin from "../../animations/coin01.json"
import lottie from "lottie-web";

const AboutUs = (props) => {

    useEffect(() => {
      lottie.loadAnimation({
        container: document.querySelector("#coin"),
        animationData: coin
      });
    }, []);

    return (
        <section className="aboutUsSection" id="about-us">
            <Container>
                <FadeInWhenVisible animation="easyFadeIn" inView delay={0.5}>
                    <div className="imageContainer">
                        <div id="coin" className="coin"/>
                    </div>
                </FadeInWhenVisible>
                <div className="payDescriptionContainer">
                    <FadeInWhenVisible animation="easyFadeIn" inView delay={1}>
                        <h1>Our Vision</h1>
                        <p>The need to incentivize our holders is one of our top priorities. This is why we built out our native token to incorporate the revolutionary positive compounding model.
                            <br/>
                            <br/>
                            Furthermore, we will be developing our custom Echtano Prediction Platform, NFT staking, Lottery and much more.
                            <br/>
                            <br/>
                            We envision a developed Echtano ecosystem rich with features adding positive value to holders, and strive to be a project other want to measure against.
                            <br/>
                            <br/>
                            This is our vision, and that will be our story.</p>
                        <div className="buttonContainer">
                            <div className="button">
                                Read the Whitepaper
                            </div>
                        </div>
                    </FadeInWhenVisible>
                </div>
            </Container>
        </section>
    )
}

export default AboutUs