export const tokenomics = [
    {
        id: 1,
        icon: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path
                    d="M20 36.6666C10.795 36.6666 3.33333 29.2049 3.33333 19.9999C3.33333 10.7949 10.795 3.33325 20 3.33325C29.205 3.33325 36.6667 10.7949 36.6667 19.9999C36.6667 29.2049 29.205 36.6666 20 36.6666ZM20 14.9999H13.3333V18.3333H28.3333L20 9.99992V14.9999ZM11.6667 21.6666L20 29.9999V24.9999H26.6667V21.6666H11.6667Z"
                    fill="#D9D4E0"/>
            </g>
        </svg>,
        active: true,
        title: 'Auto Liquidity',
        description: '4% fee on swaps add token liquidity to reduce price volatility and increase the price floor.\n' +
            'This dramatically increases the sustainability of our rewards and project.',
    },
    {
        id: 2,
        icon: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path
                    d="M30 33.3333H10V36.6667H6.66666V33.3333H4.99999C4.55797 33.3333 4.13404 33.1577 3.82148 32.8452C3.50892 32.5326 3.33333 32.1087 3.33333 31.6667V6.66667C3.33333 6.22464 3.50892 5.80072 3.82148 5.48816C4.13404 5.17559 4.55797 5 4.99999 5H35C35.442 5 35.8659 5.17559 36.1785 5.48816C36.4911 5.80072 36.6667 6.22464 36.6667 6.66667V31.6667C36.6667 32.1087 36.4911 32.5326 36.1785 32.8452C35.8659 33.1577 35.442 33.3333 35 33.3333H33.3333V36.6667H30V33.3333ZM18.3333 23.1233V28.3333H21.6667V23.1233C23.2341 22.7138 24.599 21.7477 25.5063 20.4055C26.4137 19.0634 26.8015 17.4368 26.5974 15.8296C26.3933 14.2224 25.6112 12.7445 24.3971 11.6717C23.1831 10.5989 21.6201 10.0047 20 10C18.3762 9.9984 16.8077 10.5895 15.5888 11.6623C14.3699 12.7351 13.5844 14.2159 13.3799 15.8267C13.1753 17.4376 13.5656 19.0677 14.4777 20.4111C15.3897 21.7546 16.7607 22.719 18.3333 23.1233ZM20 20C19.1159 20 18.2681 19.6488 17.643 19.0237C17.0179 18.3986 16.6667 17.5507 16.6667 16.6667C16.6667 15.7826 17.0179 14.9348 17.643 14.3096C18.2681 13.6845 19.1159 13.3333 20 13.3333C20.8841 13.3333 21.7319 13.6845 22.357 14.3096C22.9821 14.9348 23.3333 15.7826 23.3333 16.6667C23.3333 17.5507 22.9821 18.3986 22.357 19.0237C21.7319 19.6488 20.8841 20 20 20Z"
                    fill="#D9D4E0"/>
            </g>
        </svg>
        ,
        active: false,
        title: 'Treasury',
        description: '3% of the purchases go directly to the treasury which supports the ECHO Protocol. Using funds for project growth and cost',
    },
    {
        id: 3,
        icon: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M20 36.4184C19.435 36.4184 18.87 36.2784 18.3683 35.995L17.8633 35.71C9.92833 31.2467 5 22.82 5 13.7167V13.475C5 12.2767 5.65 11.1634 6.69667 10.5717L18.3667 4.00835C19.375 3.44169 20.6267 3.44002 21.6317 4.00669L33.3 10.57C34.35 11.1634 35 12.2767 35 13.475V13.7167C35 22.82 30.0717 31.2467 22.1367 35.71L21.6333 35.9934C21.13 36.2767 20.565 36.4184 20 36.4184Z"
                      fill="#D9D4E0"/>
            </g>
        </svg>
        ,
        active: false,
        title: 'Risk Free Value',
        description: '5% of the trading fees are redirected to the Echtano which helps sustain and back the staking rewards provided by the positive rebase.',
    },
    {
        id: 4,
        icon: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M4.99992 5H34.9999C35.4419 5 35.8659 5.17559 36.1784 5.48816C36.491 5.80072 36.6666 6.22464 36.6666 6.66667V33.3333C36.6666 33.7754 36.491 34.1993 36.1784 34.5118C35.8659 34.8244 35.4419 35 34.9999 35H4.99992C4.55789 35 4.13397 34.8244 3.82141 34.5118C3.50885 34.1993 3.33325 33.7754 3.33325 33.3333V6.66667C3.33325 6.22464 3.50885 5.80072 3.82141 5.48816C4.13397 5.17559 4.55789 5 4.99992 5ZM24.6549 16.3217L20.5716 20.405L17.0366 16.8683L9.96492 23.94L12.3216 26.2967L17.0366 21.5833L20.5716 25.1183L27.0116 18.6783L29.9999 21.6667V13.3333H21.6666L24.6549 16.3217Z" fill="#D9D4E0"/>
            </g>
        </svg>
        ,
        active: false,
        title: 'Matrix',
        description: '2% of all $ECHO traded are sent to the Matrix. Reducing the circulating supply and keeping the ECHO protocol stable',
    },
]

export const chunk = (arr, size) => {
    const result = [];

    for (let i = 0; i < Math.ceil(arr.length / size); i++) {
        result.push(arr.slice((i * size), (i * size) + size));
    }

    return result;
}