import React from 'react';
import './index.css'

const Modal = ({ children, handleCloseMenu }) => {
  return (
    <div className="modalContainer" onClick={handleCloseMenu}>
      { children }
    </div>
  );
};

export default Modal;